import Head from 'next/head'
import { useRouter } from 'next/router'
import { ICoinPageQuestion } from 'pages/loans/[coin]/assets/page-data/types'

const createUrl = (asPath: string): string => {
  return asPath === '/' ? 'https://coinrabbit.io' : `https://coinrabbit.io${asPath.split('?')[0]}`
}

export const useHeader = (
  title: string,
  description?: string,
  pageQuestions: ICoinPageQuestion[] = [],
  sku = 0,
  name = '',
) => {
  const router = useRouter()
  const url = createUrl(router.asPath)
  const questionsMeta = []
  console.log('PAGE QUESTIONS: ', pageQuestions);
  

  if (pageQuestions) {
    for (const pageQuestion of pageQuestions) {
      questionsMeta.push({
        '@type': 'Question',
        name: pageQuestion.title,
        acceptedAnswer: {
          '@type': 'Answer',
          text: pageQuestion.answer.replace(/<[^<>]+>/g, '').replace(/\s+/g, ' '),
        },
      })
    }
  }

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link href={url} rel="canonical" />

      <meta name="google-site-verification" content="2CE2e5_0HbXwKdDN7QrroUE60ShFwPgm-VvzFe9cQzw" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="CoinRabbit" />
      <meta property="og:image" content="https://coinrabbit.io/assets/share-img/share-img.png" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@CoinRabbit_io" />
      <meta name="twitter:creator" content="@CoinRabbit_io" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content="https://coinrabbit.io/assets/share-img/share-img.png" />

      <meta name="facebook-domain-verification" content="1rgtc7hy067ntg3uvbtb0gvnbzvwce" />

      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

      <link rel="preload" href="/fonts/inter-500-latin.01.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
      <link rel="preload" href="/fonts/inter-400-latin.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
      <link rel="preload" href="/fonts/inter-700-latin.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
      <link rel="preload" href="/fonts/poppins-400-latin.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
      <link rel="preload" href="/fonts/poppins-600-latin.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
      <link rel="preload" href="/fonts/poppins-700-latin.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'WebPage',
            headline: title,
            name: title,
            mainEntity: url,
            url: url,
            description: description,
            about: description,
            image: 'https://coinrabbit.io/assets/share-img/share-img.png',
            publisher: {
              '@type': 'Organization',
              name: 'CoinRabbit',
              logo: {
                '@type': 'ImageObject',
                url: 'https://coinrabbit.io/assets/logo/logotype-normal.svg',
                height: '60',
                width: '400',
              },
            },
            sameAs: [
              'https://twitter.com/CoinRabbit_io',
              'https://t.me/+vKoWSNIXLbZmZTQy',
              'https://www.reddit.com/user/Coin_Rabbit/posts/',
              'https://www.trustpilot.com/review/coinrabbit.io',
            ],
          }),
        }}
      />

      {sku && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'Product',
              name: name,
              image: 'https://coinrabbit.io/assets/share-img/share-img.png',
              description: description,
              sku: sku,
              brand: {
                '@type': 'Brand',
                name: 'CoinRabbit',
              },
              aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: '4.7',
                reviewCount: '42',
                worstRating: '1',
                bestRating: '5',
              },
            }),
          }}
        />
      )}

      {pageQuestions && pageQuestions.length !== 0 && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'FAQPage',
              mainEntity: questionsMeta,
            }),
          }}
        />
      )}
    </Head>
  )
}
